import cache from '../cache'

const app = {
  state: {
    appId: cache.handleAppId('get'),
    channelId: cache.handleChannelId('get'),
    deviceId: cache.handleDeviceId('get'),
    os: cache.handleOs('get'),
    username: cache.handleUsername('get'),
    accounts: cache.getAccountList()
  },
  getters: {
    appId (state) {
      return state.appId
    },
    channelId (state) {
      return state.channelId
    },
    deviceId (state) {
      return state.deviceId
    },
    os (state) {
      return state.os
    },
    accountList (state) {
      return state.accounts
    },
    currentUsername (state) {
      return state.username
    },
    currentPassword (state) {
      if (!state.username || !state.accounts) {
        return ''
      }
      const account = state.accounts.find(item => {
        return item.username === state.username
      })
      return account ? account.password : ''
    },
    currentAccount (state) {
      if (!state.username || !state.accounts) {
        return {}
      }
      const account = state.accounts.find(item => {
        return item.username === state.username
      })
      return account || {}
    },
    isArgsError (state) {
      return !state.appId || !state.channelId
    }
  },
  mutations: {
    SET_APP_ID (state, appId) {
      state.appId = appId
      cache.handleAppId('set', appId)
    },
    SET_CHANNEL_ID (state, channelId) {
      state.channelId = channelId
      cache.handleChannelId('set', channelId)
    },
    SET_DEVICE_ID (state, deviceId) {
      if (!deviceId) {
        return
      }
      state.deviceId = deviceId
      cache.handleDeviceId('set', deviceId)
    },
    SET_OS (state, os) {
      if (!os) {
        return
      }
      state.os = os
      cache.handleOs('set', os)
    },
    SET_USERNAME (state, username) {
      state.username = username
      cache.handleUsername('set', username)
    },
    SAVE_ACCOUNT_INFO (state, info) {
      if (!info) {
        return
      }
      const account = state.accounts.find(item => {
        return item.username === info.username
      })
      if (account) {
        Object.assign(account, info)
      } else {
        state.accounts.push(info)
      }
      cache.saveAccountList(state.accounts)
    },
    REMOVE_ACCOUNT_INFO (state, username) {
      if (!username) {
        return
      }
      const accounts = state.accounts.filter(item => {
        return item.username !== username
      })
      state.accounts = accounts
      cache.saveAccountList(accounts)
    }
  },
  actions: {
    ChangeAppId ({ commit, state }, appId) {
      return new Promise((resolve, reject) => {
        commit('SET_APP_ID', appId)
        resolve()
      })
    },
    ChangeChannelId ({ commit, state }, channelId) {
      return new Promise((resolve, reject) => {
        commit('SET_CHANNEL_ID', channelId)
        resolve()
      })
    },
    ChangeDeviceId ({ commit, state }, deviceId) {
      return new Promise((resolve, reject) => {
        commit('SET_DEVICE_ID', deviceId)
        resolve()
      })
    },
    ChangeOs ({ commit, state }, os) {
      return new Promise((resolve, reject) => {
        commit('SET_OS', os)
        resolve()
      })
    },
    ChangeUsername ({ commit, state }, username) {
      return new Promise((resolve, reject) => {
        commit('SET_USERNAME', username)
        resolve()
      })
    },
    SaveAccountInfo ({ commit, state }, info) {
      return new Promise((resolve, reject) => {
        if (info) {
          commit('SAVE_ACCOUNT_INFO', info)
          commit('SET_USERNAME', info.username)
        }
        resolve()
      })
    },
    RemoveAccountInfo ({ commit, state }, username) {
      return new Promise((resolve, reject) => {
        commit('REMOVE_ACCOUNT_INFO', username)
        if (state.username === username) {
          if (state.accounts.length > 0) {
            commit('SET_USERNAME', state.accounts[0].username)
          } else {
            commit('SET_USERNAME', '')
          }
        }
        resolve()
      })
    }
  }
}

export default app
