import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const load = (view) => {
  return resolve => require([`@/views/${view}.vue`], resolve)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { argsCheck: true },
    component: load('Login')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { argsCheck: true },
    component: load('Login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { argsCheck: true },
    component: load('Register')
  },
  {
    path: '/sms',
    name: 'SmsRegister',
    meta: { argsCheck: true },
    component: load('SmsRegister')
  },
  {
    path: '/forget',
    name: 'PwdForget',
    meta: { argsCheck: true },
    component: load('PwdForget')
  },
  {
    path: '/404',
    name: 'E404',
    component: load('E404')
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  routes
})

export default router
