const AppIdKey = 'appId'
const ChannelIdKey = 'channelId'
const UsernameKey = 'username'
const AccountsKey = 'accounts'
const deviceIdKey = 'deviceId'
const osKey = 'os'

function handleAppId (way, appId) {
  switch (way) {
    case 'get':
      return window.sessionStorage.getItem(AppIdKey)
    case 'set':
      return window.sessionStorage.setItem(AppIdKey, appId)
    case 'remove':
      return window.sessionStorage.removeItem(AppIdKey)
  }
}

function handleChannelId (way, channelId) {
  switch (way) {
    case 'get':
      return window.sessionStorage.getItem(ChannelIdKey)
    case 'set':
      return window.sessionStorage.setItem(ChannelIdKey, channelId)
    case 'remove':
      return window.sessionStorage.removeItem(ChannelIdKey)
  }
}

function handleUsername (way, username) {
  switch (way) {
    case 'get':
      return window.localStorage.getItem(UsernameKey)
    case 'set':
      return window.localStorage.setItem(UsernameKey, username)
    case 'remove':
      return window.localStorage.removeItem(UsernameKey)
  }
}

function handleDeviceId (way, deviceId) {
  switch (way) {
    case 'get':
      return window.localStorage.getItem(deviceIdKey)
    case 'set':
      return window.localStorage.setItem(deviceIdKey, deviceId)
    case 'remove':
      return window.localStorage.removeItem(deviceIdKey)
  }
}

function handleOs (way, os) {
  switch (way) {
    case 'get':
      return window.localStorage.getItem(osKey)
    case 'set':
      return window.localStorage.setItem(osKey, os)
    case 'remove':
      return window.localStorage.removeItem(osKey)
  }
}

function getAccountList () {
  const items = window.localStorage.getItem(AccountsKey)
  if (!items) {
    return []
  }
  return JSON.parse(items)
}

function saveAccountList (accounts) {
  if (!accounts) {
    return
  }
  window.localStorage.setItem(AccountsKey, JSON.stringify(accounts))
}

export default {
  handleAppId,
  handleChannelId,
  handleUsername,
  getAccountList,
  saveAccountList,
  handleDeviceId,
  handleOs
}
