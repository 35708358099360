import MD5 from 'crypto-js/md5'
import { btoa } from 'js-base64'

const AppKey = 'WIdb7d5x04SIpTHMmWSAlZ2QInIWx1qT'

const md5Hex = text => {
  // console.log('md5 text:', text)
  return MD5(text).toString()
}
const base64Encode = text => {
  return btoa(text).toString()
}

const genSign = data => {
  const arrays = paramsSort(data)
  const values = []
  for (const [, v] of arrays) {
    if (!v || v === '0') {
      continue
    }
    values.push(v)
  }
  values.push(AppKey)
  return md5Hex(values.join('#'))
}

const encryptData = data => {
  data.ts = Math.floor(new Date().getTime() / 1000)
  data.signWay = 'easy'
  data.sign = genSign(data)
  // console.log('encrypt data:', data)
  return data
}

const genDeviceId = params => {
  const os = params.os
  if (os.toLowerCase() === 'android') {
    const androidId = params.androidId
    const oaid = params.oaid
    // if (oaid && oaid === '00000000-0000-0000-0000-000000000000') {
    //   oaid = ''
    // }
    if (!androidId && !oaid) {
      return ''
    }
    const o = {
      imei: params.imei,
      oaid: oaid,
      androidId: androidId
    }
    const jsonStr = JSON.stringify(o)
    return 'android-' + base64Encode(jsonStr)
  } else if (os.toLowerCase() === 'ios') {
    const idfa = params.idfa
    // if (!idfa || idfa === '00000000-0000-0000-0000-000000000000') {
    if (!idfa) {
      return ''
    }
    const o = {
      idfa: idfa,
      idfv: params.idfv,
      model: 'iphone'
    }
    const jsonStr = JSON.stringify(o)
    return 'ios-' + base64Encode(jsonStr)
  } else {
    return ''
  }
}

const paramsSort = params => {
  const arrays = Object.entries(params)
  arrays.sort(paramsCompare(0))
  return arrays
}

function paramsCompare (i) {
  return function (v1, v2) {
    if (v1[i] < v2[i]) {
      return -1
    }
    if (v1[i] > v2[i]) {
      return 1
    }
    return 0
  }
}

export default {
  md5Hex,
  encryptData,
  genDeviceId
}
