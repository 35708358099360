import router from '../router'
import store from '../store'
import { message } from 'ant-design-vue'
import util from '@/common/util'

router.beforeEach((to, from, next) => {
  const query = to.query
  const app = query.app
  if (app) {
    store.dispatch('ChangeAppId', app).then()
  }
  const channel = query.channel
  if (channel) {
    store.dispatch('ChangeChannelId', channel).then()
  }
  // 生成deviceId
  const os = query.os
  const deviceId = store.getters.deviceId
  if (!deviceId && os) {
    const params = {
      os: os,
      imei: query.imei,
      idfa: query.idfa,
      oaid: query.oaid,
      androidId: query.androidId
    }
    store.dispatch('ChangeDeviceId', util.genDeviceId(params)).then()
    store.dispatch('ChangeOs', os).then()
  }
  if (to.meta.argsCheck && store.getters.isArgsError) {
    message.error('参数错误')
  }
  if (to.path === '/') {
    next('/login')
  } else {
    next()
  }
})
