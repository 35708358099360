import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button, Input, Form, Icon, message, Row, Col, Modal, List } from 'ant-design-vue'
import './common/util'
import './common/permission'
import store from './store'

Vue.config.productionTip = false
Vue.prototype.$message = message

Vue.use(Button)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(List)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
